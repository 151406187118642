<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card  :elevation="isEmbedded ? 0 : 2">
      <v-card-title class="display-2">
        <span v-if="!isEmbeddedInApp">{{$t('profile.activities.details.title')}}</span>
        <v-spacer></v-spacer>
        <v-btn v-if="activity && activity.provider == 'manual_entry'" small color="accent" :to="{name:'activityEntry', query: {id: activity.id}}"><v-icon small class="fa fa-edit mr-2" /> {{$t('shared.edit')}}</v-btn>
        <v-btn v-if="activity && !(activity.provider == 'manual_entry' || activity.provider == 'result')" small color="accent" :to="{name:'activityEdit', query: {id: activity.id}}"><v-icon small class="fa fa-edit mr-2" /> {{$t('shared.edit')}}</v-btn>
        <v-btn v-if="activity && !(activity.provider == 'result')" text  small color="accent" :to="{name:'activityDelete', query: {id: activity.id}}"><v-icon small class="fa fa-trash mr-2" /> <span class="d-none d-sm-flex">{{$t('shared.delete')}}</span></v-btn>
        <v-btn v-if="activity" text small color="accent" :to="{name:'profileUpload', query: {activityId: activity.id}}"><v-icon small class="fa fa-camera-alt mr-2" /> <span class="d-none d-sm-flex">{{$t('profile.upload-photo.photo')}}</span></v-btn>
        <v-btn text small color="accent" @click="openConnectDialog"><v-icon small class="fa fa-link mr-2" /> <span class="d-none d-sm-flex">{{$t('shared.connect')}}</span></v-btn>
      </v-card-title>
      <v-card-text v-if="activity" class="mt-4">
        <v-icon>{{$helpers.getActivityIcon(activity.type)}}</v-icon> {{ activity.type ? $t('profile.activities.types.'+activity.type) : '' }} • {{ activity.start | localDate('ddd L LTS Z') }}
        <span v-if="activity.elevation_gain">
           • {{$t('profile.activities.details.elevation-gain')}}: {{ activity.elevation_gain | elevation(profile.unit) }}
        </span>
        <span v-if="activity.elevation_loss">
           • {{$t('profile.activities.details.elevation-loss')}}: {{ activity.elevation_loss | elevation(profile.unit) }}
        </span>
        <span v-if="activity.elapsed_time_s" :title="$t('profile.activities.details.elapsed-time-description')">
           • {{$t('profile.activities.details.elapsed-time')}}: {{ activity.elapsed_time_s | duration }}
        </span>
        <span v-if="activity.kcal">
           • {{ activity.kcal }} kcal
           <span v-if="activity.provider == 'manual_entry' || activity.provider == 'quick_entry' || activity.provider == 'gpx' || activity.provider == 'result'" class="text-muted">{{$t('profile.activities.details.estimation')}}</span>
        </span>
        <span v-if="activity.modified">
           • modified
        </span>
      </v-card-text>

    <v-card-text v-if="!activity" class="mt-4 pb-0 px-2 text-center">
      <v-progress-circular indeterminate :size="100" :width="5" color="primary" class="mx-a"></v-progress-circular>
    </v-card-text>

    <v-card-text v-if="activity && activity.type == 'DAILY_SUMMARY'" class="pb-0">
      Note: daily summaries normally don't count towards challenges, unless this is explicitly enabled by the organizer. This to prevent duplicate entries.
    </v-card-text>

    <div v-if="activity" class="">
<!--       <v-card-title class="headline mb-0">Social Distancing Rules</v-card-title>
      <v-card-text>
        For our own safety, and the safety and health of others around us, it is critically important we follow social distancing guidelines.
      </v-card-text> -->
      <v-card v-if="false && activity.score" class="white--text" color="grey darken-1 mx-4">
        <v-card-title class="subtitle">
          {{$t('profile.activities.details.sodisp-points')}}
          <v-btn text icon color="white" @click.stop="$refs.pointInfo.show()" class="pl-0"><i class="fal fa-question-circle"/></v-btn>
        </v-card-title>
        <v-container class="mt-0">
          <v-row >
            <v-col class="grow">
              <p class="white--text mb-0">{{$t('profile.activities.details.points-base')}}</p>
              <h3 class="roboto subtitle">{{ activity.score.base_pts | points }}</h3>
            </v-col>
            <v-col v-if="activity.score.penalty_pts > 0" class="shrink">
              <h3 class="roboto subtitle mt-5">-</h3>
            </v-col>
            <v-col v-else-if="activity.score.bonus_pts > 0" class="shrink">
              <h3 class="roboto subtitle mt-5">+</h3>
            </v-col>
            <v-col v-if="activity.score.penalty_pts > 0" class="grow">
              <p class="white--text mb-0">Penalty</p>
              <h3 class="roboto subtitle">
                {{ activity.score.penalty_pts | points }}
                {{ activity.score ? ` (${(activity.score.penalty_pct).toFixed(0)}%)` : '' }}
              </h3>
            </v-col>
            <v-col v-else-if="activity.score.bonus_pts > 0" class="grow">
              <p class="white--text mb-0">{{$t('profile.activities.details.points-bonus')}}</p>
              <h3 class="roboto subtitle">
                {{ activity.score.bonus_pts | points }}
                {{ activity.score ? ` (${(activity.score.bonus_pct).toFixed(0)}%)` : '' }}
              </h3>
            </v-col>
            <v-col v-if="activity.score.penalty_pts > 0 || activity.score.bonus_pts > 0" class="shrink">
              <h3 class="roboto subtitle mt-5">=</h3>
            </v-col>
            <v-col class="grow">
              <p class="white--text mb-0">{{$t('profile.activities.details.points-total')}}</p>
              <h3 class="roboto subtitle">{{ activity.score.total_pts | points }}</h3>              
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-container class="mt-0">
        <v-row>
          <v-col v-if="activity.type == 'DAILY_SUMMARY' && activity.steps" cols="12" sm="4" md="4">
            <v-card class="white--text" color="primary darken-3">
              <v-card-text class="white--text">{{$t('profile.activities.steps')}}</v-card-text>
              <v-card-title class="subtitle">{{ activity.steps }}</v-card-title>
            </v-card>
          </v-col>
          <v-col v-if="activity.type == 'STAIR_CLIMBING'" cols="12" sm="4" md="4">
            <v-card class="white--text" color="primary darken-1">
              <v-card-text class="white--text">{{$t('profile.activities.stairs')}}</v-card-text>
              <v-card-title class="subtitle">{{ activity.custom }}</v-card-title>
            </v-card>
          </v-col>
          <v-col v-if="activity.type != 'STAIR_CLIMBING'" cols="12" sm="4" md="4">
            <v-card class="white--text" color="primary darken-1">
              <v-card-text class="white--text">{{$t('profile.activities.distance')}}</v-card-text>
              <v-card-title class="subtitle">{{ activity.dist  | distance(profile.unit) }}</v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-card class="white--text" color="primary darken-2">
              <v-card-text class="white--text">{{$t('profile.activities.duration')}}</v-card-text>
              <v-card-title class="subtitle">{{ activity.time_s | duration }}</v-card-title>
            </v-card>
          </v-col>
          <v-col v-if="activity.type != 'DAILY_SUMMARY' && activity.dist > 10 && activity.time_s > 10" cols="12" sm="4" md="4">
            <v-card class="white--text" color="primary darken-3">
              <v-card-text class="white--text">{{$t('profile.activities.pace')}}</v-card-text>
              <v-card-title class="subtitle">{{ activity.time_s / (activity.dist / 1000) | pace(profile.unit, activity.type) }}</v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <PointsInfoDialog ref="pointInfo"/> <!-- Must be before map! -->
    <v-divider  v-if="activity"/>
    
    <div v-if="activity && activity.race_links && activity.race_links.length > 0">
      <v-card-title class="headline">{{$t('profile.activities.details.race-results')}}</v-card-title>
      <v-card-text>
        {{$t('profile.activities.details.race-results-description')}}
      </v-card-text>
      <v-simple-table fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">{{$t('results.cols.event')}}</th>
              <th class="text-left">{{$t('results.cols.race')}}</th>
              <th class="text-left">{{$t('results.cols.scoring')}}</th>
              <!--<th class="text-left pr-0"><v-btn text small color="accent" @click.stop="showScaledDialog=true" class="pl-0">Race distance <i class="fal fa-question-circle"/></v-btn> </th>-->
              <th class="text-left pr-0"><v-btn text small color="accent" @click.stop="showScaledDialog=true" class="pl-0">{{$t('results.cols.scaled-result')}} <i class="fal fa-question-circle"/></v-btn> </th>
              <!-- <th class="text-left pr-0"><v-btn text small color="accent" @click.stop="showScaledDialog=true" class="pl-0">Scaled time <i class="fal fa-question-circle"/></v-btn> </th> -->
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in activity.race_links" :key="idx">
              <td class="">{{ item.event_name }}</td>
              <td class="">{{ item.race_name }}</td>
              <td class=""><v-icon :title="item.scoring">{{$helpers.getScoringIcon(item.scoring)}}</v-icon></td>
              <!--<td class="">{{ item.race_dist  | distance(profile.unit) }}</td>-->
              <td v-if="item.scoring == 'RESULT'" class="">{{ -1*item.val | duration }}</td>
              <td v-if="item.scoring == 'DISTANCE'" class="">{{ item.val | distance(profile.unit) }}</td>
              <td v-if="item.scoring == 'TIME'" class="">{{ item.val | duration }}</td>
              <td v-if="item.scoring == 'ELEVATION'" class="">{{ item.val | distance(profile.unit) }}</td>
              <td v-if="item.scoring == 'STEPS'" class="">{{ item.val | int }} steps</td>
              <td v-if="item.scoring == 'BADGES'" class=""></td>
              <td v-if="item.scoring == 'CUSTOM'" class="">
                {{ item.val }} {{ item.custom || $t('profile.activities.points') | lowercase }} 
                <TooltipDialog v-if="item.scoring_desc" title="Scoring for this race" :text="item.scoring_desc" />
              </td>
              <!-- <td class="">{{ item.race_time_s | duration }}</td> -->
              <td class=""><v-btn text color="primary" :to="{name: 'race', params: { id:item.event_id, raceId: item.race_id }}">{{$t('shared.view')}}</v-btn></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider/>
    </div>
    
    <div v-if="tilescores && tilescores.length > 0">
      <v-card-title class="headline">{{$t('profile.activities.details.tile-scores')}}</v-card-title>
      <v-card-text>{{$t('profile.activities.details.tile-scores-description')}}</v-card-text>
      <v-simple-table fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">{{$t('results.cols.position')}}</th>
              <th class="text-left">{{$t('profile.activities.details.tile')}}</th>
              <th class="text-left">{{$t('profile.activities.distance')}}</th>
              <th class="text-left">{{$t('profile.activities.points')}}</th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in tilescores" :key="idx">
              <td style="min-width:120px;">
                <v-chip v-if="item.pos_after" color="accent"># {{ item.pos_after }}</v-chip> 
                <span v-if="item.pos_gained == null || item.pos_gained == 0" class="ml-2" title="Same position as before"></span>
                <span v-else class="ml-2" :title="`You gained ${item.pos_gained} postion(s).`"><v-icon color="green">fa-angle-double-up</v-icon> {{item.pos_gained}}</span>
              </td>
              <td class="">{{ item.name }}</td>
              <td class="">{{ item.d | distance(profile.unit) }}</td>
              <td class=""><ActivityScoreChip :points="item.score.total_pts"/></td>
              <td class=""><v-btn text color="primary" :to="{name: 'tile', params: { id:item.id, type: activity.type.toLowerCase() }}">{{$t('shared.view')}}</v-btn></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider/>
    </div>

    <v-dialog v-model="showScaledDialog">
      <v-card>  
        <v-card-title class="display-1">sodisp Points</v-card-title>
        <v-card-text class="mt-4">
          <p class="mb-0">For events, the points are scaled to the race distance to make a fair comparison between workouts with a different distance.</p>
          <p>For instance, if you run 11km instead of the 10km race distance (10% extra) your points are also reduced by 10% to make it comparable with others who did exactly 10 km.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showScaledDialog = false">Close</v-btn>
        </v-card-actions>      
      </v-card>
    </v-dialog>

    <v-dialog v-if="profile" v-model="showConnectDialog" width="400">
      <v-card>
        <v-card-title>{{$t('profile.activities.details.connect-with-events')}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;">
          <p>{{$t('profile.activities.details.connect-with-events-description')}}</p>
          <v-checkbox v-for="(item, idx) in profile.events" :key="idx" v-model="connectEventIds" :label="item.name" :value="item.id" hide-details></v-checkbox>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="accent" text @click="showConnectDialog = false">{{$t('shared.close')}}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" @click="connectWithEvents" :loading="$store.getters.isLoading">{{$t('shared.connect')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-title v-if="routePolyDecoded" class="headline">{{$t('profile.activities.details.route-map')}}</v-card-title>
    <LeafletMap 
      v-if="routePolyDecoded"
      ref="map"
      style="height: 500px; width: 100%; z-index: 2;" 
      :zoom="zoom"
      :center="startCoord"
      :loader="addRouteToMap"
      />

      <br/>
    </v-card>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import profileService from "@/services/profileService";
import activityService from "@/services/activityService";
import PolylineUtil from "@/plugins/Polyline.encoded.js";
import PointsInfoDialog from "@/components/PointsInfoDialog.vue";
import ActivityScoreChip from "@/components/ActivityScoreChip";
import TooltipDialog from "@/components/TooltipDialog";
import LeafletMap from "@/components/LeafletMap";
import siteData from '@/data/site.json'
import Header from './_Header.vue';

export default {
  name: "Activity",
  components: {
    PointsInfoDialog,
    ActivityScoreChip,
    TooltipDialog,
    LeafletMap,
    Header,
  },
  props: {
  },
  data() {
    return {
      siteData: siteData,
      activity: null,
      tilescores: null,
      profile: null,
      zoom: 11,
      routePolyDecoded: null,
      showScaledDialog: false,
      showConnectDialog: false,
      connectEventIds: [],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      breadcrumbItems: [
        { text: this.$t('profile.title'), exact: true, to: {name: 'profile'} },
        { text: this.$t('profile.activities.title'), exact: true, to: {name: 'activities'} },
        { text: this.$t('profile.activities.details.title'), disabled: true },
      ],
    };
  },
  async mounted() {
    await this.loadData();
    

    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });

  },
  methods: {
    async loadData() {
      if (this.user) {
        this.profile = (await profileService.get(/*full:*/false)).data;
        await this.loadActivity(this.$route.params.id);
      }
    },
    async addRouteToMap(leaflet) {
      var poly = leaflet.addPolyline(this.routePolyDecoded, { color: "#ffffff", weight: 10 });
      leaflet.addPolyline(this.routePolyDecoded, { color: "#008AFF", weight: 5 });
      leaflet.addCircle(this.startCoord, { color: "white", weight: 10 });
      leaflet.addCircle(this.startCoord, { color: "#00B46E", weight: 7 });
      leaflet.addCircle(this.stopCoord, { color: "white", weight: 10 });
      leaflet.addCircle(this.stopCoord, { color: "#950700", weight: 7 });
      leaflet.map.fitBounds(poly.getBounds());
    },

    async openConnectDialog() {
      // we need to load connected events
      this.profile = (await profileService.get(/*full:*/true)).data;
      this.showConnectDialog=true;
    },
    async loadActivity(id) {
        activityService.impersonationProfileId = this.$route.query.profileId;
        var response = await activityService.get(id);
        this.activity = response.data;

        this.$nextTick(async () => {
          this.routePolyDecoded = this.activity.route_summary == null ? null : PolylineUtil.decode(this.activity.route_summary);
          // get all connected and unique event ids and preselect those
          this.connectEventIds = this.activity.race_links == null ? [] : [...new Set(this.activity.race_links.map(x => x.event_id))];
          this.tilescores = (await activityService.getTilescores(this.activity.id, this.activity.type)).data.data;
        });
    },
    async connectWithEvents() {
      if (this.connectEventIds && this.connectEventIds.length > 0) {
        const response = (await activityService.connect(this.activity.id, this.connectEventIds)).data;
        await this.loadActivity(this.activity.id);
        this.$toast.success('Successfully connected this activity with the selected events.');
        this.showConnectDialog = false;
      }
      else {
        this.$toast.error('Please select one or more events you want this activity to connect with.');
      }
    },
  },
  computed: {
    startCoord() {
      return this.routePolyDecoded == null ? null : this.routePolyDecoded[0];
    },

    stopCoord() {
      return this.routePolyDecoded == null ? null : this.routePolyDecoded[this.routePolyDecoded.length - 1];
    },

    routePoly() {
      //console.log('coord', PolylineUtil);
      return this.routePolyDecoded;
      //return [this.activity.start_coord.lat, this.activity.start_coord.lng]
    },

    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },

    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

